body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.compare-previous-value{
  color: #f44336;
}

.compare-changed-value{
  color: #004d40;
}

.compare-arrow{
  margin-bottom: -5px;
}